<template>
  <Container
    indent="base"
    tag="footer"
    class="flex flex-col flex-wrap gap-6 pb-32 pt-22 md:flex-row lg:gap-10"
    style="
      background: radial-gradient(
        100% 100% at 0% 100%,
        #bfaf82 0%,
        #d1c5a5 100%
      );
    "
  >
    <div class="flex min-w-fit shrink grow flex-col items-start gap-4">
      <h5 class="text-base font-semibold uppercase tracking-wide text-white">
        Wyhlenstrasse
      </h5>
      <ul class="flex flex-col gap-3">
        <li
          v-for="{ page } in globals?.footer.tree"
          :key="page.id"
          class="-mr-4 block"
        >
          <NuxtLink
            class="focus-default text-base font-medium text-gray-400 underline-offset-0 transition-[text-underline-offset] hover:underline hover:underline-offset-4"
            :to="page.url"
            >{{ page.title }}</NuxtLink
          >
        </li>
      </ul>
    </div>
    <div class="flex min-w-fit shrink grow flex-col items-start gap-4">
      <h5 class="text-base font-semibold uppercase tracking-wide text-white">
        Kontakt Erstvermietung
      </h5>
      <Bard
        :html="globals?.general.contact_rent"
        class="[&_a]:text-gold-600 [&_a]:transition-all hover:[&_a]:text-white [&_p]:font-medium [&_p]:text-gray-400"
      />
    </div>
    <div class="flex min-w-fit shrink grow flex-col items-start gap-4">
      <h5 class="text-base font-semibold uppercase tracking-wide text-white">
        Eigentümer und Verwaltung
      </h5>
      <Bard
        :html="globals?.general?.address"
        class="[&_p]:text-gray-400r [&_a]:transition-all hover:[&_a]:text-white [&_p]:font-medium"
      />
    </div>
    <div
      class="flex flex-row flex-wrap gap-10 max-xl:mt-5 max-xl:items-center xl:flex-col xl:items-end"
    >
      <NuxtLink
        target="_blank"
        to="https://vogelimmobilien.ch/de"
        aria-label="Vogel Immobilien"
      >
        <IconVogelImmobilien class="w-56 grayscale" />
      </NuxtLink>
      <NuxtLink
        target="_blank"
        to="https://futura.ch/"
        aria-label="Futura Vorsorge"
      >
        <IconFutura class="w-40 grayscale" />
      </NuxtLink>
    </div>
  </Container>
</template>

<script lang="ts" setup>
const globals = inject(GlobalsKey)
</script>
